* {
  margin: 0px;
  padding: 0px;
}


.tab-dashboard{
  background-color: #2f3333 !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background-color: #ffffff !important;
}
