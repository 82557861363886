.shadow {
  box-shadow: 2px 3px 2px #efefef;
  background-color: #ffffff !important;
}

.shadow .nav-link {
  display: block;
  font-size: 20px;
  margin: 0px 14px;
  padding: 0.5rem 1rem;
}

.active {
  padding-bottom: 0 !important;
  border-bottom: 3px solid #d63192 !important;
}

.padding12 {
  padding: 12px;
}

.bgFooter {
  background: transparent linear-gradient(178deg, #0bbff7 0%, #102044 100%) 0% 0% no-repeat padding-box;
  opacity: 0.62;
  height: 250px;
  position: relative;
  top: -126px;
  z-index: -2;
}

.bgFooter hr {
  width: 80%;
  margin-left: 10%;
  margin-top: 153px;
  border-bottom: 2px solid #fff;
}

.footerNew {
  /* margin-top: 192px; */
  text-align: center;
  color: #fff;
  font-weight: bold;
}

.justify {
  text-align: justify;
}

.ml52 {
  margin-left: 52px;
}

.mTop100 {
  margin-top: 123px;
}

.bgNew {
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
  /* Resize the background image to cover the entire container */
}

.mb-100 {
  margin-top: 95px;
  width: 100%;
}

.c2cHeight {
  width: 100%;
  min-height: 60vh;
  margin-top: 95px !important;
}

.navLink {
  color: #3dbada !important;
  padding: 9px !important;
  padding-bottom: 0px !important;
  outline: none !important;
  margin-bottom: 12px !important;
}

.img-center {
  width: 70% !important;
  margin: auto !important;
  display: block !important;
  margin-top: 100px !important;
}

.m-auto {
  margin: auto;
}

.accordian-header {
  background: #68bbda !important;
  color: #fff !important;
}