.test {
    font-size: 40px;
    color: red;
}

.width-80 {
    width: 80%!important;
    margin: auto;
}

.table {
    margin: auto;
    display: table;
    border-spacing: 0;
    width: 80%!important;
    border-collapse: collapse;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.paper {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.w-100 {
    width: 100%;
}

.formControlFull {
    width: 100
}

.createButton {
    width: 100%;
    margin-top: 20px!important;
    margin-bottom: 20px!important;
}
.m-auto{
    margin: auto!important;
}
.react-datepicker__input-container{
    /* position: relative;
    display: inline-block;
    width: 100%;
    text-align: left; */
    text-align: left;
    display: grid!important;
    padding: 10px;
}

.react-datepicker__input-container input {
    padding: 5px;
    border: none;
    border-bottom: 1px solid #949494;
    outline: none;
    padding-left: 0px;
}
.react-datepicker-wrapper{
    display: inherit!important;
}
.errmsg{
    color: red;
    font-size: 22px;
}